import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NoData } from "../../../Assets/Icons/NoData.svg";
import { ReactComponent as CheckpointProgress } from "../../../Assets/Icons/CheckpointProgress.svg";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableRow,
} from "@questionprodev/shared-components";
import LoadingPage from "../../../Pages/LoadingPage";
import { PerPageThen } from "../../../Constants/Glopalconstants";
import Pagination from "../Pagination/Pagination";
import useCustomNavigate from "../../../hooks/useCustomNavigate";
import { getClassNameByParam, strongCopyData } from "../../../Utils";
import {
  getInitiativeContributorAnalytics,
  getInitiativeContributorAnalyticsCount,
  getInitiativeContributorAnalyticsError,
  getInitiativeContributorAnalyticsLoading,
  getInitiativeContributorAnalyticsMonths,
  getInitiativeOwnerAnalytics,
  getInitiativeOwnerAnalyticsCount,
  getInitiativeOwnerAnalyticsError,
  getInitiativeOwnerAnalyticsLoading,
  getInitiativeOwnerAnalyticsMonths,
  getUserAnalyticsAsync,
} from "../../../Store/slices/analytics";

const InitiativeAnalytic = ({ type, emailAddress }) => {
  const { navigateToInitiative } = useCustomNavigate();
  const dispatch = useDispatch();

  const initiativeOwnerAnalytics = useSelector(getInitiativeOwnerAnalytics);
  const initiativeOwnerAnalyticsMonths = useSelector(
    getInitiativeOwnerAnalyticsMonths
  );
  const initiativeOwnerAnalyticsCount = useSelector(
    getInitiativeOwnerAnalyticsCount
  );
  const initiativeOwnerAnalyticsLoading = useSelector(
    getInitiativeOwnerAnalyticsLoading
  );
  const initiativeOwnerAnalyticsError = useSelector(
    getInitiativeOwnerAnalyticsError
  );

  const initiativeContributorAnalytics = useSelector(
    getInitiativeContributorAnalytics
  );
  const initiativeContributorAnalyticsMonths = useSelector(
    getInitiativeContributorAnalyticsMonths
  );
  const initiativeContributorAnalyticsCount = useSelector(
    getInitiativeContributorAnalyticsCount
  );
  const initiativeContributorAnalyticsLoading = useSelector(
    getInitiativeContributorAnalyticsLoading
  );
  const initiativeContributorAnalyticsError = useSelector(
    getInitiativeContributorAnalyticsError
  );

  const [currentPage, setCurrentPage] = useState(1);

  const changePage = useCallback(
    (page) => {
      setCurrentPage(page);
      dispatch(
        getUserAnalyticsAsync({
          type,
          emailAddress,
          offset:
            type === "initiative_owner"
              ? initiativeOwnerAnalytics?.length
              : initiativeContributorAnalytics?.length,
        })
      );
    },
    [
      dispatch,
      emailAddress,
      initiativeContributorAnalytics?.length,
      initiativeOwnerAnalytics?.length,
      type,
    ]
  );

  const goToInitiative = (id, status) => {
    navigateToInitiative({
      initiativeID: id,
      status,
    });
  };

  const analytics =
    (type === "initiative_owner" && {
      analytic: initiativeOwnerAnalytics,
      months: initiativeOwnerAnalyticsMonths,
      count: initiativeOwnerAnalyticsCount,
      loading: initiativeOwnerAnalyticsLoading,
      error: initiativeOwnerAnalyticsError,
    }) ||
    (type === "initiative_contributor" && {
      analytic: initiativeContributorAnalytics,
      months: initiativeContributorAnalyticsMonths,
      count: initiativeContributorAnalyticsCount,
      loading: initiativeContributorAnalyticsLoading,
      error: initiativeContributorAnalyticsError,
    });

  if (!type) {
    return <div className={"error-message"}>Something went wrong</div>;
  }

  return (
    <>
      {analytics.error ? (
        <div className={"error-message"}>{analytics.error}</div>
      ) : (
        <>
          {analytics.loading ? (
            <LoadingPage />
          ) : (
            <>
              <div className={"initiative-analytic-modal--content"}>
                {analytics?.analytic?.length ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableData th={true}>Name</TableData>
                        <TableData th={true}>Lane</TableData>
                        {analytics.months.map((month) => (
                          <TableData key={month?.month} th={true}>
                            {month?.month}
                          </TableData>
                        ))}
                        <TableData th={true} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {analytics.analytic.map((initiativeAnalytic) => (
                        <TableRow key={initiativeAnalytic.id}>
                          <TableData dataLabel="Name">
                            <span
                              className={"table-link-name"}
                              onClick={() =>
                                goToInitiative(
                                  initiativeAnalytic?.id,
                                  initiativeAnalytic?.status
                                )
                              }
                            >
                              {initiativeAnalytic?.name}
                            </span>
                          </TableData>
                          <TableData dataLabel="Lane">
                            {initiativeAnalytic?.lane}
                          </TableData>
                          {strongCopyData(initiativeAnalytic?.checkpoint).map(
                            (point, index) => (
                              <TableData key={index} dataLabel={point?.month}>
                                <>
                                  <div className="initiative-progress">
                                    <div
                                      style={{
                                        width: `${point?.percent}%`,
                                      }}
                                      className="initiative-progress--filled-block"
                                    >
                                      <span
                                        className={`initiative-progress--start-percent  ${getClassNameByParam(
                                          point?.percent,
                                          "initiative-progress--filled-percent"
                                        )}`}
                                      >{`${point?.percent}%`}</span>
                                    </div>
                                  </div>
                                </>
                              </TableData>
                            )
                          )}
                          <TableData>
                            <span>
                              <CheckpointProgress />
                              {` ${
                                initiativeAnalytic?.checkpoint[2].percent -
                                initiativeAnalytic?.checkpoint[1].percent
                              }%`}
                            </span>
                          </TableData>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div className={"no-data"}>
                    <NoData />
                  </div>
                )}
              </div>

              {analytics.count > PerPageThen ? (
                <Pagination
                  loading={false}
                  perPage={PerPageThen}
                  currentPage={currentPage}
                  allCount={analytics.count}
                  changePage={changePage}
                />
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default InitiativeAnalytic;
