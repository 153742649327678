import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as NoData } from "../../../Assets/Icons/NoData.svg";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableRow,
} from "@questionprodev/shared-components";
import LoadingPage from "../../../Pages/LoadingPage";
import { PerPageThen } from "../../../Constants/Glopalconstants";
import useCustomNavigate from "../../../hooks/useCustomNavigate";
import Pagination from "../Pagination/Pagination";
import {
  getTaskContributorAnalytics,
  getTaskContributorAnalyticsCount,
  getTaskContributorAnalyticsError,
  getTaskContributorAnalyticsLoading,
  getTaskOwnerAnalytics,
  getTaskOwnerAnalyticsCount,
  getTaskOwnerAnalyticsError,
  getTaskOwnerAnalyticsLoading,
  getUserAnalyticsAsync,
} from "../../../Store/slices/analytics";

const TaskAnalytic = ({ type, emailAddress }) => {
  const { navigateToInitiative } = useCustomNavigate();
  const dispatch = useDispatch();

  const taskOwnerAnalytics = useSelector(getTaskOwnerAnalytics);
  const taskOwnerAnalyticsCount = useSelector(getTaskOwnerAnalyticsCount);
  const taskOwnerAnalyticsLoading = useSelector(getTaskOwnerAnalyticsLoading);
  const taskOwnerAnalyticsError = useSelector(getTaskOwnerAnalyticsError);

  const taskContributorAnalytics = useSelector(getTaskContributorAnalytics);
  const taskContributorAnalyticsCount = useSelector(
    getTaskContributorAnalyticsCount
  );
  const taskContributorAnalyticsLoading = useSelector(
    getTaskContributorAnalyticsLoading
  );
  const taskContributorAnalyticsError = useSelector(
    getTaskContributorAnalyticsError
  );

  const [currentPage, setCurrentPage] = useState(1);
  const options = { day: "numeric", month: "long", year: "numeric" };

  const changePage = useCallback(
    (page) => {
      setCurrentPage(page);
      dispatch(
        getUserAnalyticsAsync({
          type,
          emailAddress,
          offset:
            type === "task_owner"
              ? taskOwnerAnalytics.length
              : taskContributorAnalytics.length,
        })
      );
    },
    [
      dispatch,
      emailAddress,
      taskContributorAnalytics.length,
      taskOwnerAnalytics.length,
      type,
    ]
  );
  const goToInitiative = (id, status) => {
    navigateToInitiative({
      initiativeID: id,
      status,
    });
  };
  const analytics =
    (type === "task_owner" && {
      analytic: taskOwnerAnalytics,
      count: taskOwnerAnalyticsCount,
      loading: taskOwnerAnalyticsLoading,
      error: taskOwnerAnalyticsError,
    }) ||
    (type === "task_contributor" && {
      analytic: taskContributorAnalytics,
      count: taskContributorAnalyticsCount,
      loading: taskContributorAnalyticsLoading,
      error: taskContributorAnalyticsError,
    });

  if (!type) {
    return <div className={"error-message"}>Something went wrong</div>;
  }

  return (
    <>
      {analytics.error ? (
        <div className={"error-message"}>{analytics.error}</div>
      ) : (
        <>
          {analytics?.loading ? (
            <LoadingPage />
          ) : (
            <>
              <div className={"task-analytic-modal--content"}>
                {analytics?.analytic.length ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableData th={true}>Task</TableData>
                        <TableData th={true}>Due</TableData>
                        <TableData th={true}>Milestone</TableData>
                        <TableData th={true}>Status</TableData>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {analytics.analytic.map((taskAnalytic) => (
                        <TableRow key={taskAnalytic.id}>
                          <TableData dataLabel="Task">
                            <span
                              className={"table-link-name"}
                              onClick={() =>
                                goToInitiative(
                                  taskAnalytic?.initiative?.id,
                                  taskAnalytic?.initiative?.status
                                )
                              }
                            >
                              {taskAnalytic?.name}
                            </span>
                          </TableData>
                          <TableData dataLabel="Due">
                            {new Date(taskAnalytic?.dueDate).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </TableData>
                          <TableData dataLabel="Milestone">
                            {taskAnalytic?.milestone?.date
                              ? new Date(
                                  taskAnalytic.milestone.date
                                ).toLocaleDateString("en-US", options)
                              : "-"}
                          </TableData>
                          <TableData dataLabel="Status">
                            {taskAnalytic?.status}
                          </TableData>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <div className={"no-data"}>
                    <NoData />
                  </div>
                )}
              </div>
              {analytics.count > PerPageThen ? (
                <Pagination
                  loading={false}
                  perPage={PerPageThen}
                  currentPage={currentPage}
                  allCount={analytics.count}
                  changePage={changePage}
                />
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TaskAnalytic;
